@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

//Fixes issue with Tailwind and Material 15. With new version in future should be able to remove
.mdc-notched-outline__notch {
  border-right: none;
}

.mat-calendar-body-selected {
  @apply bg-primary-400 text-light;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  @apply bg-primary-200;
}
.mat-calendar-body-today{
  @apply bg-primary-200;
}




